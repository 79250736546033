import { Options } from '@desights/desights.js/dist/src/interfaces';

const {
  REACT_APP_RELAYER_PK = '',
  REACT_APP_SIGNER_PK = '',
  REACT_APP_CHAIN_ID = '',
  REACT_APP_FILEBASE_ACCESS_KEY = '',
  REACT_APP_FILEBASE_SECRET_KEY = '',
  REACT_APP_FILEBASE_AUTH_TOKEN = '',
  REACT_APP_DEFAULT_GAS_LIMIT = '',
  REACT_APP_DEFAULT_GAS_PRICE = '',
  REACT_APP_LOG_LEVEL = '',
  REACT_APP_GAS_BUFFER = '',
  REACT_APP_IPFS_GATEWAY = '',
  REACT_APP_MAX_PRIORITY_FEE_PER_GAS = '',
  REACT_APP_MAX_FEE_PER_GAS = '',
  REACT_APP_BUCKET_USERS = '',
  REACT_APP_BUCKET_ORGS = '',
  REACT_APP_BUCKET_CHALLENGES = '',
  REACT_APP_BUCKET_SUBMISSIONS = '',
  REACT_APP_REGISTRY = '',
  REACT_APP_CONFIRMATIONS_COUNT = '',
  REACT_APP_GAS_STATION_URL = '',
  REACT_APP_BUCKET_REVIEWS = '',
} = process.env;

export const envOpts: Options = {
  relayerPK: REACT_APP_RELAYER_PK,
  signerPK: REACT_APP_SIGNER_PK,
  chainId: Number(REACT_APP_CHAIN_ID ?? 137),
  filebaseAccessKey: REACT_APP_FILEBASE_ACCESS_KEY,
  filebaseSecretKey: REACT_APP_FILEBASE_SECRET_KEY,
  filebaseAuthToken: REACT_APP_FILEBASE_AUTH_TOKEN,
  defaultGasLimit: REACT_APP_DEFAULT_GAS_LIMIT,
  defaultGasPrice: REACT_APP_DEFAULT_GAS_PRICE,
  gasBuffer: REACT_APP_GAS_BUFFER,
  logLevel: REACT_APP_LOG_LEVEL,
  ipfsGateway: REACT_APP_IPFS_GATEWAY,
  maxPriorityFeePerGas: REACT_APP_MAX_PRIORITY_FEE_PER_GAS,
  maxFeePerGas: REACT_APP_MAX_FEE_PER_GAS,
  bucketUsers: REACT_APP_BUCKET_USERS,
  bucketOrgs: REACT_APP_BUCKET_ORGS,
  bucketChallenges: REACT_APP_BUCKET_CHALLENGES,
  registry: REACT_APP_REGISTRY,
  bucketSubmissions: REACT_APP_BUCKET_SUBMISSIONS,
  confirmationsCount: Number(REACT_APP_CONFIRMATIONS_COUNT),
  gasStationUrl: REACT_APP_GAS_STATION_URL,
  bucketReviews: REACT_APP_BUCKET_REVIEWS,
};

export const IrysConstants = {
  '80001': {
    name: 'matic',
    url: 'https://devnet.irys.xyz',
    token: 'matic',
    arweaveURL: 'https://arweave.net/',
  },
  '80002': {
    name: 'amoy',
    url: 'https://devnet.irys.xyz',
    token: 'matic',
    arweaveURL: 'https://arweave.net/',
  },
  '137': {
    name: 'polygon',
    url: 'https://node2.irys.xyz',
    token: 'matic',
    arweaveURL: 'https://arweave.net/',
  },
  '11155111': {
    name: 'sepolia',
    url: 'https://devnet.irys.xyz',
    token: 'ethereum',
    arweaveURL: 'https://gateway.irys.xyz/',
  },
};

export const DISCORD_ROLE_ID = '1220613239961026560';
