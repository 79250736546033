import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../@types';
import { resetRootState } from '../Store';
import { GUEST_CHALLENGE_LIST_PATH, heroFeaturesInfo } from '../Utils/Constants';
import { ReactComponent as HeroImage } from '../assets/hero-image.svg';
import CommonButton from '../Components/CommonButton';
import { updateGuestStateAction } from '../Store/Actions';
import { useNavigate } from 'react-router-dom';

const Hero = () => {
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    localStorage.setItem('persistedstate', JSON.stringify({}));
    dispatch(resetRootState());
  }, []);
  const setGuestHandler = () => {
    // navigate(`${CHALLENGE_PATH+LIST_PATH}`);
    navigate(GUEST_CHALLENGE_LIST_PATH);
    dispatch(updateGuestStateAction(true));
  };
  return (
    <div className='dark:bg-black'>
      <div className='dark:bg-black min-h-screen'>
        <div className='relative overflow-hidden'>
          <main>
            <div className='dark:bg-black pt-10 bg-gray-50 sm:pt-16 lg:pt-8 lg:overflow-hidden pb-4'>
              <div className='mx-auto max-w-7xl lg:px-8'>
                <div className='lg:grid lg:grid-cols-2 lg:gap-8'>
                  <div className='mx-auto max-w-md px-4 sm:max-w-2xl sm:px-6 sm:text-center lg:px-0 lg:text-left lg:flex lg:items-center z-50'>
                    <div className='lg:py-24'>
                      <h1 className='mt-4 text-4xl font-extrabold text-gray-800 sm:mt-5 sm:text-6xl lg:mt-6 xl:text-6xl'>
                        <span className='block dark:text-white'>The Playground for</span>
                        <span className='block whitespace-nowrap text-transparent bg-clip-text bg-gradient-to-b from-primary-300 dark:from-primary-300 dark:to-primary-500 to-primary-600'>
                          AI and ML innovators
                        </span>
                      </h1>
                      <p className='mt-3 text-base dark:text-white text-gray-800 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl'>
                        A web3 competitions platform to solve Data and AI Challenges through
                        Crowdsourced solutions with shared Ownership
                      </p>
                      <div className='mt-10 sm:mt-12'>
                        <div className='mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-start'>
                          <div className='rounded-md w-fit flex'>
                            {/* <ConnectWallet responsive={false} /> */}
                            <CommonButton
                              buttonClassName='text-lg'
                              onClick={setGuestHandler}
                              className='w-40 font-semibold bg-gradient-to-b from-primary-300 dark:from-primary-300 dark:to-primary-500 to-primary-600 hover:dark:from-primary-400 hover:dark:to-primary-600'
                              buttonText={'Get Started'}
                            />
                            <CommonButton
                              buttonClassName='text-lg'
                              onClick={() => {
                                window.open('https://docs.desights.ai/', '_blank');
                              }}
                              className=' font-semibold text-lg w-40  ml-2 bg-gradient-to-b from-secondary-300 dark:from-secondary-300 dark:to-secondary-500 to-secondary-600'
                              buttonText={'Learn More'}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='md:flex justify-center mt-5 md:ml-5'>
                    <HeroImage
                      className='hero-image opacity-30 lg:opacity-100 absolute sm:block'
                      viewBox='0 0 600 600'
                    />
                  </div>
                </div>
              </div>
            </div>
            <div id='features' className='dark:bg-black overflow-hidden z-50'>
              <div className='relative max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:px-8'>
                <svg
                  className='hidden md:block sm:absolute top-0 sm:left-full sm:h-auto lg:left-auto lg:right-full lg:translate-x-2/3 lg:translate-y-1/4'
                  width={404}
                  height={784}
                  fill='none'
                  viewBox='0 0 404 784'
                  aria-hidden='true'
                >
                  <defs>
                    <pattern
                      id='8b1b5f72-e944-4457-af67-0c6d15a99f38'
                      x={0}
                      y={0}
                      width={20}
                      height={20}
                      patternUnits='userSpaceOnUse'
                    >
                      <rect
                        x={0}
                        y={0}
                        width={4}
                        height={4}
                        className='text-gray-200'
                        fill='currentColor'
                      />
                    </pattern>
                  </defs>
                  <rect
                    width={404}
                    height={784}
                    fill='url(#8b1b5f72-e944-4457-af67-0c6d15a99f38)'
                  />
                </svg>
                <div className=' relative lg:grid lg:grid-cols-3 lg:gap-x-8'>
                  <div className='lg:col-span-1'>
                    <h2 className='text-3xl font-extrabold dark:text-white text-gray-900 sm:text-4xl'>
                      Redefining AI & ML landscape.
                    </h2>
                  </div>
                  <dl className='mt-10 space-y-10 sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-x-8 sm:gap-y-10 lg:mt-0 lg:col-span-2'>
                    {heroFeaturesInfo.map((feature) => (
                      <div key={feature.name}>
                        <dt>
                          <div className='flex items-center justify-center h-12 w-12 rounded-md bg-gradient-to-b from-primary-400 to-primary-500 dark:to-primary-700 text-white'>
                            <feature.icon className='h-6 w-6 ' aria-hidden='true' />
                          </div>
                          <p className='mt-5 text-lg leading-6 font-medium dark:text-white text-gray-900'>
                            {feature.name}
                          </p>
                        </dt>
                        <dd className='mt-2 text-base text-gray-500'>{feature.description}</dd>
                      </div>
                    ))}
                  </dl>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </div>
  );
};

export default Hero;
